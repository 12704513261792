<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';

	export default {
		name: "AppContactUs",
		extends: Base,
		data() {
			return {
				Name: "AppContactUs",
				mrLevel: {},
				input: {},
				contact: {},
				AppContacts: [],
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			showModalEditContact(id) {
				$("#edit_address").modal()
				BOGen.apirest("/" + this.Name, {
					id: id,
					type: 'showContacts'
				}, (err, resp) => {
					if (err) return swal(err.resp.message, '', 'warning')
					this.input = resp.data
				}, "POST")
			},
			viewContact(id) {
				$("#detail_contact").modal()
				BOGen.apirest("/" + this.Name, {
					id: id,
					type: 'viewContact'
				}, (err, resp) => {
					if (err) return swal(err.resp.message, '', 'warning')
					this.contact = resp.data
				}, "POST")
			},
			addContact(e, callback) {
				if (e && e.btnLoading()) return;
				this.input.type = 'addContact'
				BOGen.apirest("/" + this.Name, this.input, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error) return swal(resp.message, '', 'warning')
					$("#add_address").modal("hide")
					this.input = {}
					this.refreshData()
					swal(resp.message, '', 'success')
				}, "POST")
			},
			editContact(e, callback) {
				if (e && e.btnLoading()) return;
				this.input.type = 'editContact'
				BOGen.apirest("/" + this.Name, this.input, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error) return swal(resp.message, '', 'warning')
					$("#edit_address").modal("hide")
					this.input = {}
					this.refreshData()
					swal(resp.message, '', 'success')
				}, "POST")
			},
			deleteContact(id, k) {
				swal({
					title: "Are you sure to delete this contact?",
					text: "",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, Sure!"],
					dangerMode: true,
				}).then((ok) => {
					if (ok) {
						BOGen.apirest("/" + this.Name, {
							type: "deleteContact",
							id: id
						}, () => {
							// callback delete item
						}, "POST")
						this.AppContacts.splice(k, 1);
						swal("Deleted!", "Contact has been deleted.", "success");
					}
				});
			},
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}} List <span
											v-if="data.total">({{(data.total||0).format()}})</span></h5>
								</div>
								<div class="col-sm-3"></div>
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="acu_name"></SortField>
									</th>
									<th>{{fields.acu_email}}</th>
									<th>{{fields.acu_message}}</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.acu_name}}</td>
									<td>{{v.acu_email}}</td>
									<td>{{(v.acu_message||'').limitChar(125)}}</td>
									<td class="btn-action">
										<a href="javascript:;" class="icon_action" @click="viewContact(v.id)" v-tooltip="'View Detail'"><i class="ti-eye"></i></a>
										<a href="javascript:;" v-if="page.moduleRole('Remove')" class="icon_action" @click="deleteItem($event,k)" v-tooltip="'Remove'"><i
												class="ti-trash"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="panel-body">
						<div class="pull-right">
							<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-5">
							<BoField name="bu_full_name" v-model="row.bu_full_name"></BoField>
							<BoField name="bu_email_address" v-model="row.bu_email_address" :attr="{type:'email'}">
							</BoField>
							<BoField name="bu_username" v-model="row.bu_username" keyFilter="usernameKey">
								<span slot="help" class="help-block">
									<small>{{ fields.bu_username }} for login to Backoffice page</small>
								</span>
							</BoField>
							<BoField name="bu_password" v-model="row.bu_password" :attr="{type:'password'}"></BoField>
						</div>
						<div class="col-sm-4">
							<BoField name="bu_phone" v-model="row.bu_phone"></BoField>
							<BoField name="bu_level">
								<select2 name="bu_level" v-bind="validation('bu_status')" :options="mrLevel"
									:object="['bul_id','bul_name']" v-model="row.bu_level">
									<option value="">-- Select Level --</option>
								</select2>
							</BoField>
							<BoField name="bu_is_active">
								<div class="row">
									<radio name="bu_is_active" v-model="row.bu_is_active" option="Y" :attr="validation('bu_is_active')">
										Active</radio>
									<radio name="bu_is_active" v-model="row.bu_is_active" option="N">Inactive</radio>
								</div>
							</BoField>
						</div>
						<div class="col-sm-3">
							<BoField name="bu_pic">
								<Uploader name="bu_pic" type="users" uploadType="cropping" :param="{thumbnail:true}"
									v-model="row.bu_pic"></Uploader>
							</BoField>
						</div>
						<div class="col-xs-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit
									form <i class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
		<div id="add_address" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team"
			aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="prod_cat">Add Contact</h4>
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
					</div>
					<VForm @resp="addContact" method="post">
						<div class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label for="">{{fields.ac_type}}</label>
										<select name="" id="" v-model="input.ac_type" class="form-control custom-select">
											<option value="W" selected>WhatsApp</option>
											<option value="E">Email</option>
										</select>
									</div>
								</div>
								<div class="col-12">
									<div class="form-group">
										<label for="">{{fields.ac_contacts}}</label>
										<input type="text" v-model="input.ac_contacts" name="" id="" class="form-control">
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">+62</span>
											</div>
											<input type="tel" v-model="input.ac_contacts" name="" id="" class="form-control">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-rounded btn-info">Submit</button>
						</div>
					</VForm>
				</div>
			</div>
		</div>
		<div id="edit_address" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="prod_cat">Edit Contact</h4>
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
					</div>
					<VForm @resp="editContact" method="post">
						<div class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label for="">{{fields.ac_type}}</label>
										<select name="" id="" v-model="input.ac_type" class="form-control custom-select">
											<option value="W" selected>WhatsApp</option>
											<option value="E">Email</option>
										</select>
									</div>
								</div>
								<div class="col-12">
									<div class="form-group">
										<label for="">{{fields.ac_contacts}}</label>
										<input type="text" v-model="input.ac_contacts" name="" id="" class="form-control">
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">+62</span>
											</div>
											<input type="tel" v-model="input.ac_contacts" name="" id="" class="form-control">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-rounded btn-info">Submit</button>
						</div>
					</VForm>
				</div>
			</div>
		</div>
		<div id="detail_contact" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="prod_cat">Detail Contact Form</h4>
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
					</div>
					<div class="modal-body">
						<div class="row">

							<div class="col-md-6">
								<div class="form-group">
									<label class="control-label">{{fields.acu_ip}}</label>
									<p>{{contact.acu_ip}}</p>
								</div>
							</div>

							<div class="col-md-6">
								<div class="form-group">
									<label class="control-label">{{fields.acu_name}}</label>
									<p>{{contact.acu_name}}</p>
								</div>
							</div>

							<div class="col-md-6">
								<div class="form-group">
									<label class="control-label">{{fields.acu_email}}</label>
									<p>{{contact.acu_email}}</p>
								</div>
							</div>

							<div class="col-md-6">
								<div class="form-group">
									<label class="control-label">{{fields.acu_phone}}</label>
									<p>{{contact.acu_phone}}</p>
								</div>
							</div>

							<div class="col-md-12">
								<div class="form-group">
									<label class="control-label">{{fields.acu_message}}</label>
									<p v-html="contact.acu_message"></p>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>